import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { loader } from "../../loader";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";
import { popup_alert } from "../../popup_alert";
import { ENDPOINT } from "../../axios/apiConfig";
import { postData } from "../../axios/apiHelper"

const GetMedpakDetails = () => {
    let path_image = process.env.REACT_APP_ASSETS_PATH_INFORMED_DESIGN;
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [heading, setHeading] = useState([]);
    const [sortingCount, setSortingCount] = useState(0);
    const [sortingCountEmail, setSortingCountEmail] = useState(0);

    const [sortingName, setSortingName] = useState(0);
    const [sortingEmail, setSortingEmail] = useState(0);

    const [distributeData, setDistributeData] = useState({});
    const [search, setSearch] = useState("");
    const [updatedData, setUpdatedData] = useState([]);
    const location = useLocation();
    const { distribute_id } = location.state;
    const [reminderChecked, setReminderChecked] = useState({})
    const [allChecked, setAllChecked] = useState(false);

    const [isStopped, setStopped] = useState(0); 

    useEffect(() => {
        // console.log("reminder state-->", reminderChecked)
        setData([]);
        getCampaignReaderDetails(0);
    }, []);

    const getCampaignReaderDetails = async (flag = 0) => {
        axios.defaults.baseURL = process.env.REACT_APP_API_KEY;
        const body = {
            user_id: localStorage.getItem("user_id"),
            distribute_id: distribute_id,
            sync_flag: flag,
        };
        loader("show");
        await axios
            .post(`distributes/get_campaign_readers_details`, body)
            .then((res) => {
                // console.log(res.data.response.data.readers,'===>reminder')
                if (res.data.status_code == 200) {
                    if (flag == 1) {
                        setData([]);
                        setUpdatedData([]);
                    }
                    const readers = res.data.response.data.readers;
                    //   const readers =[{
                    //     "first_name": " Kristiawan ",
                    //     "last_name": "",
                    //     "email": "kristiawan@sapharma.co.id",
                    //     "email_read": "Yes",
                    //     "heading": ["Agenda_clicked","One_source_link","Monograph_link"],
                    //     "Agenda_clicked":"No",
                    //     "One_source_link":"No",
                    //     "Monograph_link":"No",
                    //     "article_register": "No",
                    //     "article_already_register": 0,
                    //     "all_read_info": [],
                    //     "already_email_sent": 0,
                    //     "user_id": 29836253,
                    //     "list": "One source internal 3"
                    // }]
                    let heading = [];
                    let data = readers.length > 0 ? readers[0] : undefined;

                    if (data != undefined && data.heading != undefined) {
                        heading = data.heading;
                    }
                    else {
                        heading.push("Link Open");
                    }

                    setData(readers);
                    setHeading(heading);
                    setUpdatedData(readers);
                    setDistributeData(res.data.response.data.distribute_data);
                    const status =res.data.response.data.distribute_data?.campaign_status;
                    setStopped(status === 6 ? 1 : 0);

                    const updatedReminderChecked = {};
                    readers.forEach((user) => {
                       if (user.reminder === 1) {
                          updatedReminderChecked[user.user_id] = true;
                       }
                    });
                    setReminderChecked((prevData) => ({ ...prevData, ...updatedReminderChecked }));

                } else {
                    toast.warning(res.data.message);
                }
                loader("hide");
            })
            .catch((err) => {
                loader("hide");
                toast.error("Something went wrong");
                console.log(err);
            });
    };

    const sortName = () => {
        let normalArr = [];
        normalArr = data;
        // console.log(sortingName);
        if (sortingName === 0) {
            normalArr.sort((a, b) => {
                if (a.first_name === null) {
                    return -1;
                }
                if (b.first_name === null) {
                    return 1;
                }
                if (a.first_name === b.first_name) {
                    return 0;
                }
                return a.first_name.toLowerCase() > b.first_name.toLowerCase()
                    ? 1
                    : b.first_name.toLowerCase() > a.first_name.toLowerCase()
                        ? -1
                        : 0;
            });
        } else {
            normalArr.sort((a, b) => {
                if (a.first_name === null) {
                    return 1;
                }
                if (b.first_name === null) {
                    return -1;
                }

                if (a.first_name === b.first_name) {
                    return 0;
                }
                return a.first_name.toLowerCase() < b.first_name.toLowerCase()
                    ? 1
                    : b.first_name.toLowerCase() < a.first_name.toLowerCase()
                        ? -1
                        : 0;
            });
        }
        setSortingCountEmail(0);
        setData(normalArr);
        setSortingName(1 - sortingName);
        setSortingCount(sortingCount + 1);
    };

    const sortEmail = () => {
        let normalArr = [];
        normalArr = data;
        if (sortingEmail === 0) {
            normalArr.sort((a, b) =>
                a.email.toLowerCase() > b.email.toLowerCase()
                    ? 1
                    : b.email.toLowerCase() > a.email.toLowerCase()
                        ? -1
                        : 0
            );
        } else {
            normalArr.sort((a, b) =>
                a.email.toLowerCase() < b.email.toLowerCase()
                    ? 1
                    : b.email.toLowerCase() < a.email.toLowerCase()
                        ? -1
                        : 0
            );
        }

        setSortingCount(0);
        setData(normalArr);
        setSortingEmail(1 - sortingEmail);
        setSortingCountEmail(sortingCountEmail + 1);
    };

    const searchChange = (e) => {
        let search_str = e.target.value.trim();
        setSearch(search_str);

        if (e.target.value === "") {
            setData(updatedData);
        }
    };

    const submitHandler = (event) => {
        event.preventDefault();
        let r_table = [];
        updatedData.find(function (item) {
            if (item.first_name !== null || item.email !== "") {
                if (item.first_name.includes(search) || item.email.includes(search)) {
                    r_table.push(item);
                }
            }
        });
        if (r_table.length > 0) {
            setData(r_table);
        } else {
            // popup_alert({
            //   visible: "show",
            //   message: "Data not found",
            //   type: "error",
            // });
            setData([]);
        }
        return false;
    };

    const syncData = (e) => {
        e.preventDefault();
        getCampaignReaderDetails(1);
    };

    // const handleOnCheckedAll = (e) => {
    //     console.log("e", e?.target?.checked)

    //     data?.map((item) => {
    //         if (item?.article_already_register !== 1) {
    //             setReminderChecked(prevData => ({ ...prevData, [item.user_id]: e?.target?.checked }))
    //         }

    //     })
    //     console.log(data,'===>data')
    // }

    // const handleOnReminderChange = (e, item) => {
    //     let updateReminderChecked = { ...reminderChecked }
    //     updateReminderChecked[item?.user_id] = e?.target?.checked
    //     setReminderChecked(updateReminderChecked)
    // }


    const handleOnCheckedAll = (e) => {
        const newAllChecked = e?.target?.checked;
        setReminderChecked((prevData) => {
           const updatedChecked = {};
           data?.forEach((item) => {
              if (item?.article_already_register !== 1) {
                 updatedChecked[item.user_id] = newAllChecked;
              }
           });
           return { ...prevData, ...updatedChecked };
        });
     };
  
     const handleOnReminderChange = (e, item) => {
        setReminderChecked((prevData) => ({
           ...prevData,
           [item?.user_id]: e?.target?.checked,
        }));
     };
  
     useEffect(() => {
        const allSingleCheckboxesChecked = data.length > 0 ? data?.every((item) => {
           return (
              item?.article_already_register === 1 ||
              reminderChecked[item.user_id]
           );
        }):false;
        setAllChecked(allSingleCheckboxesChecked);
     }, [data, reminderChecked]);

    const checkedCount = Object.values(reminderChecked).filter(value => value).length;

    // const saveReminderMail = async () => {
    //     try {
    //         loader("show")
    //         let data = {
    //             distribute_id: distributeData?.distribute_id,
    //             compaign_id: distributeData?.campaign_id,
    //             reminderUsers: reminderChecked
    //         }
    //         console.log("data--->", data)
    //         // const res = await postData(ENDPOINT.GET_EMAIL_REMINDER, data)
    //         // console.log("res--->", res)

    //     } catch (err) {
    //         console.log("--err", err)
    //     } finally {
    //         loader("hide")
    //     }
    // }

    const saveReminderMail = async () => {
        try {
            // console.log(reminderChecked,"reminderChecked");
           loader('show');
        //    Object.values(reminderChecked).some((value) => value)
            if (Object.keys(reminderChecked).length !== 0) {
              let data = {
                 distribute_id: distributeData?.distribute_id,
                 compaign_id: distributeData?.campaign_id,
                 reminderUsers: reminderChecked,
                 user_id: localStorage.getItem("user_id"),
              };
              const reminderCheckedValues = Object.values(reminderChecked);
              const isAnyReminderChecked = reminderCheckedValues.includes(true);
     
              await axios
                 .post(`emailapi/change-email-reminder`, data)
                 .then((res) => {
                    // console.log("Response from the server:", reminderChecked);
                    // if (res.data.status_code === 200) {
                    //    toast.success("User reminder status updated successfully.");
                    // } else {
                    //    toast.warning(res.data.message);
                    // }
                    if(isAnyReminderChecked){
                        toast.success("Reminders blocked successfully");
                    }else{
                        toast.success("Reminders unblocked successfully");
                    }
                    loader('hide');
                 })
                 .catch((err) => {
                    loader('hide');
                    toast.error("Something went wrong");
                    console.log(err);
                 });
                 //   const res = await postData(ENDPOINT.GET_EMAIL_REMINDER, data)
                //   console.log("res--->", res);
           } else {
            //   toast.error("Please select at least one reader for reminder");
              toast.error("Please tick/untick on block reminder column to update the user status. ");
           }
        } catch (err) {
           console.log("--err", err);
        } finally {
           loader('hide');
        }
    };

      const handleStoppedCampaign = async (isChecked) => {
        const status = isChecked ? 1 : 0;
        const body = {
          user_id: localStorage.getItem("user_id"),
          campaign_id: distributeData?.campaign_id,
          status: status,
        };
    
        loader("show");
        try {
          const res = await axios.post(`emailapi/stop_campaign`, body);
          if (res.data.status_code === 200) {
            setStopped(status);
            toast.success(
              isChecked
                ? "Campaign status update successfully"
                : "Campaign status update successfully"
            );
          } else {
            toast.warning(res.data.message);
          }
        } catch (err) {
          toast.error("Something went wrong");
          console.log(err);
        } finally {
          loader("hide");
        }
      };

    return (
        <>
            {" "}
            <div className="col right-sidebar">
                <div className="custom-container">
                    <div className="row">

                        <section className="search-hcp smart-list-view">
                            <div className="header-btn-left">
                                {true ? (
                                    <Link
                                        to={{
                                            pathname: "/EmailStatss",
                                        }}
                                    >
                                        <button className="btn btn-primary btn-bordered back">
                                            Back
                                        </button>
                                    </Link>
                                ) : (
                                    <button className="btn btn-primary btn-bordered back">
                                        Back
                                    </button>
                                )}
                            </div>
                            <div className="result-hcp-table">
                                <div className="table-title">
                                    <h4>
                                        {/* {getlistname} <span>| {editList.length}</span> */}
                                    </h4>
                                </div>
                                <div className="selected-hcp-list">
                                    {" "}
                                    <div className="selected-hcp-list_detail">
                                        <ul>
                                            <li>
                                                <label>Campaign ID:</label>
                                                <span>{distributeData.c_id}</span>
                                            </li>
                                            <li>
                                                <label>Date:</label>
                                                <span>{distributeData.sent_data}</span>
                                            </li>
                                            <li>
                                                <label>Subject</label>
                                                <span>{distributeData.subject}</span>
                                            </li>
                                            <li>
                                                <label>Smart list</label>
                                                <span>{distributeData.list}</span>
                                            </li>
                                            <li>
                                                <label>Total mail sent:</label>
                                                <span>{distributeData.total_sent_count}</span>
                                            </li>
                                            <li>
                                                <label>Email read:</label>
                                                <span>{distributeData.total_read_count}</span>
                                            </li>
                                            <li>
                                                <label>Pending read email:</label>
                                                <span>{distributeData.total_pending_count}</span>
                                            </li>
                                            <li>
                                                <label>Bounce count:</label>
                                                <span>{distributeData.total_bouns_count}</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="color_opt d-flex">
                                        <div className="col-md-6">
                                            <div className="green-box">
                                                <div className="box"></div>
                                                <p>
                                                    Reader already registered in system from a previous
                                                    campaign.{" "}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="orange-box">
                                                <div className="box"></div>
                                                <p>
                                                    Email already sent to this user from a previous
                                                    campaign.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table_xls search_view sync">
                                        <div className="smart-list-btns">
                                            <div className="top-left-action d-flex align-items-center w-100" style={{ gap: "0 10px" }}>

                                                <div className="search-bar">
                                                    <form
                                                        className="d-flex"
                                                        onSubmit={(e) => submitHandler(e)}
                                                    >
                                                        <input
                                                            className="form-control me-2"
                                                            type="search"
                                                            placeholder="Search"
                                                            aria-label="Search"
                                                            onChange={(e) => searchChange(e)}
                                                        />

                                                        {!search ? (
                                                            <button
                                                                className="btn btn-outline-success"
                                                                type="submit"
                                                            >
                                                                <svg
                                                                    width="16"
                                                                    height="16"
                                                                    viewBox="0 0 16 16"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <path
                                                                        d="M15.8045 14.862L11.2545 10.312C12.1359 9.22334 12.6665 7.84 12.6665 6.33334C12.6665 2.84134 9.82522 0 6.33325 0C2.84128 0 0 2.84131 0 6.33331C0 9.82531 2.84132 12.6667 6.33328 12.6667C7.83992 12.6667 9.22325 12.136 10.3119 11.2547L14.8619 15.8047C14.9919 15.9347 15.1625 16 15.3332 16C15.5039 16 15.6745 15.9347 15.8045 15.8047C16.0652 15.544 16.0652 15.1227 15.8045 14.862ZM6.33328 11.3333C3.57597 11.3333 1.33333 9.09066 1.33333 6.33331C1.33333 3.57597 3.57597 1.33331 6.33328 1.33331C9.0906 1.33331 11.3332 3.57597 11.3332 6.33331C11.3332 9.09066 9.09057 11.3333 6.33328 11.3333Z"
                                                                        fill="#97B6CF"
                                                                    ></path>
                                                                </svg>
                                                            </button>
                                                        ) : null}
                                                    </form>
                                                </div>
                                                <button
                                                    className="btn btn-primary btn-bordered back"
                                                    onClick={(e) => syncData(e)}
                                                >
                                                    Sync
                                                    <svg
                                                        data-name="Layer 1"
                                                        id="Layer_1"
                                                        viewBox="0 0 512 512"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            fill="#0066be"
                                                            d="M64,256H34A222,222,0,0,1,430,118.15V85h30V190H355V160h67.27A192.21,192.21,0,0,0,256,64C150.13,64,64,150.13,64,256Zm384,0c0,105.87-86.13,192-192,192A192.21,192.21,0,0,1,89.73,352H157V322H52V427H82V393.85A222,222,0,0,0,478,256Z"
                                                        />
                                                    </svg>
                                                </button>

                                                {/* <div className="campaign_stopped">
                                                 <p>Campaign Stopped</p>
                                                 <div className="switch">
                                                <label className="switch-light">
                                                    <input
                                                    type="checkbox"
                                                    checked={isStopped === 1}
                                                    onChange={(e) => handleStoppedCampaign(e.target.checked)}
                                                    />
                                                    <span>
                                                    <span className="switch-btn active">No</span>
                                                    <span className="switch-btn">Yes</span>
                                                    </span>
                                                    <a className="btn"></a>
                                                </label>
                                                </div>
                                                </div> */}
                                            </div>

                                            {/* <div className="all-checked-reminder">

                                                    <input
                                                        type="checkbox" id="checked_all"
                                                         // checked={Object.keys(reminderChecked)?.length&&Object.values(reminderChecked).every((value) => value)}
                                                        onChange={(e) => handleOnCheckedAll(e)}
                                                    />
                                                    <label for="checked_all">Reminder</label>
                                                </div> */}
                                            {/* <div className="top-right-action">

                                               <div className="all-checked-reminder">
                                                  <p for="checked_all">Selected Readers : {checkedCount}</p>
                                                </div>

                                                <div className="save-reminder">
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary save btn-filled"
                                                        onClick={saveReminderMail}
                                                    >
                                                        Save
                                                    </button>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-end">
                                    {/* <div className="all-checked-reminder">
                                        <input
                                        type="checkbox"
                                        id="checked_all"
                                        checked={allChecked}
                                        onChange={(e) => handleOnCheckedAll(e)}
                                        />
                                        <label htmlFor="checked_all">Select All</label>
                                    </div> */}
                                    </div>
                                    <div className="table_xls">
                                        <table className="table get-details" id="table-to-xls">
                                            <thead className="sticky-header">
                                                <tr>
                                                    <>
                                                        <th scope="col">
                                                            First name
                                                            <div className="hcp-sort">
                                                                {sortingCount == 0 ? (
                                                                    <>
                                                                        <button
                                                                            className="btn btn-outline-primary"
                                                                            onClick={sortName}
                                                                        >
                                                                            <img
                                                                                src={path_image + "sort.svg"}
                                                                                alt="Shorting"
                                                                            />
                                                                        </button>
                                                                    </>
                                                                ) : sortingName == 0 ? (
                                                                    <>
                                                                        <button
                                                                            className="btn btn-outline-primary desc"
                                                                            onClick={sortName}
                                                                        >
                                                                            <img
                                                                                src={path_image + "sort-decending.svg"}
                                                                                alt="Shorting"
                                                                            />
                                                                        </button>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <button
                                                                            className="btn btn-outline-primary asc"
                                                                            onClick={sortName}
                                                                        >
                                                                            <img
                                                                                src={path_image + "sort-assending.svg"}
                                                                                alt="Shorting"
                                                                            />
                                                                        </button>
                                                                    </>
                                                                )}
                                                            </div>
                                                        </th>
                                                        <th scope="col">Last name</th>
                                                        <th scope="col">
                                                            Email
                                                            <div className="hcp-sort">
                                                                {sortingCountEmail == 0 ? (
                                                                    <>
                                                                        <button
                                                                            className="btn btn-outline-primary"
                                                                            onClick={sortEmail}
                                                                        >
                                                                            <img
                                                                                src={path_image + "sort.svg"}
                                                                                alt="Shorting"
                                                                            />
                                                                        </button>
                                                                    </>
                                                                ) : sortingEmail == 0 ? (
                                                                    <>
                                                                        <button
                                                                            className="btn btn-outline-primary desc"
                                                                            onClick={sortEmail}
                                                                        >
                                                                            <img
                                                                                src={path_image + "sort-decending.svg"}
                                                                                alt="Shorting"
                                                                            />
                                                                        </button>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <button
                                                                            className="btn btn-outline-primary asc"
                                                                            onClick={sortEmail}
                                                                        >
                                                                            <img
                                                                                src={path_image + "sort-assending.svg"}
                                                                                alt="Shorting"
                                                                            />
                                                                        </button>
                                                                    </>
                                                                )}
                                                            </div>
                                                        </th>
                                                        <th scope="col">Email read</th>
                                                        {heading.map((element) => {
                                                            return <th scope="col">{element.replace(/_/g, " ")}</th>

                                                        })}
                                                        <th scope="col">Registered</th>
                                                        {typeof data != "undefined" && data.length > 0 ? (
                                                            <>
                                                                {data[0]?.all_read_info &&
                                                                    data[0].all_read_info != ""
                                                                    ? Object.keys(data[0].all_read_info).map(
                                                                        (key, index) => (
                                                                            <>
                                                                                <th>Link Open {index + 1}</th>
                                                                                <th>Registered {index + 1}</th>
                                                                            </>
                                                                        )
                                                                    )
                                                                    : ""}
                                                            </>
                                                        ) : (
                                                            ""
                                                        )}
                                                        {/* <th scope="col">Block Reminder</th> */}
                                                    </>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {typeof data != "undefined" && data.length > 0 ? (
                                                    data.map((item, index) => (
                                                        <>
                                                            {item.email != "" ? (
                                                                <tr
                                                                    key={index}
                                                                    className={
                                                                        item?.article_already_register == 1
                                                                            ? "green"
                                                                            : item?.already_email_sent == 1
                                                                                ? "orange"
                                                                                : ""
                                                                    }
                                                                >
                                                                    <td>{item.first_name}</td>
                                                                    <td>{item.last_name}</td>
                                                                    <td>{item.email}</td>
                                                                    <td>{item.email_read}</td>
                                                                    {item.article_open != undefined ? <td>{item.article_open}</td> : heading.map((element) => {
                                                                        return <td>{item[element] != undefined ? item[element] : ""}</td>
                                                                    })}
                                                                    <td>{item.article_register}</td>
                                                                    {item?.all_read_info &&
                                                                        item.all_read_info != ""
                                                                        ? Object.keys(item.all_read_info).map(
                                                                            (key) => (
                                                                                <>
                                                                                    <td>
                                                                                        {
                                                                                            item.all_read_info[key]
                                                                                                .article_read
                                                                                        }
                                                                                    </td>
                                                                                    <td>
                                                                                        {
                                                                                            item.all_read_info[key]
                                                                                                .article_registered
                                                                                        }
                                                                                    </td>
                                                                                </>
                                                                            )
                                                                        )
                                                                        : ""}
                                                                    {/* <td>
                                                                        {item?.article_already_register !== 1 ?
                                                                            <input
                                                                                type="checkbox"
                                                                                checked={reminderChecked[item?.user_id] ? true : false}
                                                                                onChange={(e) => handleOnReminderChange(e, item)}
                                                                            />
                                                                            : "N/A"}
                                                                    </td> */}

                                                                    {/* <td key={item.user_id}>
                                                                        {item.article_already_register !== 1 ? (
                                                                            <input
                                                                                type="checkbox"
                                                                                checked={reminderChecked[item.user_id] || false}
                                                                                onChange={(e) => handleOnReminderChange(e, item)}
                                                                            />
                                                                        ) : (
                                                                            'N/A'
                                                                        )}
                                                                        </td> */}
                                                                </tr>
                                                            ) : (
                                                                <tr>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td className="removed_td centered">
                                                                        Removed
                                                                    </td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                </tr>
                                                            )}
                                                        </>
                                                    ))
                                                ) : (
                                                    <tr className="data-not-found">
                                                        <td colspan="6">
                                                            <h4>No Data Found</h4>
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            {/* <input type="hidden" value={updateCounter} /> */}
                        </section>
                    </div>
                </div>
            </div>
        </>
    );
};
export default GetMedpakDetails;