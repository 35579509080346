import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import AliceCarousel from "react-alice-carousel";
import { Link, useLocation } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { loader } from "../../../loader";
import React, { useEffect, useState, useRef } from "react";
import { postData, postFormData } from "../../../axios/apiHelper";
import { ENDPOINT } from "../../../axios/apiConfig";
import SimpleReactValidator from "simple-react-validator";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import RenderPdf from "./RenderPdf";
import Tooltip from "react-bootstrap/Tooltip";
import {
  Button,
  Col,
  Dropdown,
  Modal,
  DropdownButton,
  Form,
  Row,
  ProgressBar,
  Tab,
  Tabs,
} from "react-bootstrap";
import Select from "react-select";
let path_image = process.env.REACT_APP_ASSETS_PATH_INFORMED_DESIGN;
//pdf id  3846
//ebook 3899
const newLanguage = {
  0 : 'English',
  1 : 'Italian',
  2 : 'Germany',
  3 : 'Spanish',
  4 : 'Russian',
};
const PreviewContent = () => {
  const rdLikeArray=["56Ek4feL/1A8mZgIKQWEqg==","sNl1hra39QmFk9HwvXETJA==","MXl8m36VZFYXpgFVz3Pg0g=="]
  const isLikeRdAccount= rdLikeArray.includes(localStorage.getItem("user_id"))
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const { state } = useLocation();
  const [articleId, setArticleId] = useState(
    typeof state?.pdfId !== "undefined" ? state?.pdfId : ""
  );
  const [isEdit, setIsEdit] = useState(
    typeof state?.isEdit !== "undefined" ? state?.isEdit : 0
  );
  const [allowStateVideo, setAllowStateVideo] = useState(
    typeof state?.allowVideo !== "undefined"
      ? state?.allowVideo
        ? true
        : false
      : false
  );
  const [pdfData, setPdfData] = useState([]);
  const [editTitle, setEditTitle] = useState(false);
  const [publishStatus, setPublishStatus] = useState(false);
  const [trigger, setTrigger] = useState(0);
  const [titleChange, setTitleChange] = useState("");
  const [titleNew, setTitleNew] = useState("");
  const responsive = {
    0: { items: 1 },
    568: { items: 2 },
    1024: { items: 5 },
  };
  const [activeIndex, setActiveIndex] = useState(0);
  const slidePrev = () => setActiveIndex(activeIndex - 1);
  const slideNext = () => setActiveIndex(activeIndex + 1);
  const syncActiveIndex = ({ item }) => setActiveIndex(item);
  const [templateId, setTemplateId] = useState();
  const [newTemplateClicked, setNewTemplateClicked] = useState(false);
  const [templateClickedd, setTemplateClicked] = useState(false);
  const [pdfFileId, setPdfFileId] = useState();
  const [templatePdf, setTemplatePdf] = useState();
  const [templateAllVersion, setTemplateAllVersion] = useState(0);
  const [nextFlag, setNextFlag] = useState(0);
  const [templateName, setTemplateName] = useState("");
  const [userInputs, setUserInputs] = useState({});
  const [updateFlag, setUpdateFlag] = useState(0);
  const [apiCallBackFlag, setApiCallBackFlag] = useState(0);
  const location = useLocation();
  // console.log(location,'publish page')
  const BrokenImage =
    "https://docintel.s3-eu-west-1.amazonaws.com/cover/default/default.png";
    const [isKey, setIsKey] = useState(0);

  useEffect(() => {
    getArticleData();
  }, []);

  const getArticleData = async () => {
    try {
      loader("show");
      if (typeof articleId === "undefined") {
        if (state?.pdfId) {
          setArticleId(state?.pdfId);
        }
      }

      let body = {
        pdfId: typeof state?.pdfId !== "undefined" ? state?.pdfId : articleId,
      };
      const res = await postData(ENDPOINT.LIBRARYGETARTICLE, body);
      setPdfData(res?.data?.data);
      setApiCallBackFlag(apiCallBackFlag + 1);

      if (res?.data?.data?.file_type && res?.data?.data?.file_type == "pdf") {
        setNewTemplateClicked(true);
      }
      loader("hide");

      if (res?.data?.data?.file_type != "pdf") {
        setTimeout(function () {
          const div_img = document.querySelector(
            ".alice-carousel__wrapper img"
          );
          if (typeof div_img !== "undefined" || div_img != null) {
            div_img.click();
          }
        }, 300);
      }
    } catch (err) {
      console.log("--err", err);
      loader("hide");
    }
  };

  const updateArticleTitle = async (title) => {
    try {
      loader("show");
      let formData = new FormData();
      formData.append("pdfId", articleId);
      formData.append("type", pdfData?.file_type);
      formData.append("userId", localStorage.getItem("user_id"));
      formData.append("title", title);
      if (pdfData?.file_type && pdfData.file_type == "ebook") {
        formData.append("fileId", pdfFileId);
      }
      await postFormData(ENDPOINT.UPDATE_PDF_FILE, formData, {
        header: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (pdfData?.file_type && pdfData.file_type == "ebook") {
        let pdfIndex = pdfData.ebookData.findIndex((el) => el.id === pdfFileId);
        pdfData.ebookData[pdfIndex].title = title;
        setPdfData(pdfData);
        setTemplateName(title);
        setTitleNew(title);
      } else {
        pdfData.title = title;
      }
    } catch (err) {
      console.log(err);
    } finally {
      loader("hide");
    }
  };

  const templateClicked = (template, e) => {
    
    setIsKey((prevKey) => prevKey + 1);
    const div = document.querySelector("img.select_mm");
    setNewTemplateClicked(true);
    if (div) {
      div.classList.remove("select_mm");
    }

    if (pdfData?.file_type && pdfData.file_type == "ebook") {
      let pdfIndex = pdfData.ebookData.findIndex((el) => el.id === template.id);
      let nextItem = pdfData.ebookData[pdfIndex + 1];
      if (typeof nextItem !== "undefined") {
        setNextFlag(1);
      } else {
        setNextFlag(0);
      }
    } else {
      setNextFlag(0);
    }
    setPublishStatus(false);
    setTemplatePdf(template?.file_name);
    setTemplateName(template?.title);
    setTitleNew(template?.title);
    setTemplateClicked(true);
    setPdfFileId(template.id);
    setEditTitle(false);
    setTemplateAllVersion(template?.ie_allversion);
    e.target.classList.toggle("select_mm");
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = () => {
    if (pdfData?.file_type && pdfData.file_type == "ebook") {
      if (typeof pdfFileId === "undefined") {
        toast.warning("Please select the Ebook chapter.");
      } else {
        setShow(true);
      }
    } else {
      setShow(true);
    }
  };

  const handleChange = (e, isSelectedName) => {
    setUpdateFlag(1);
    if (e?.target?.files?.length < 1) {
      return;
    }
    setUserInputs({
      ...userInputs,
      [isSelectedName ? isSelectedName : e?.target?.name]: isSelectedName
        ? e?.target?.files
          ? e?.target?.files
          : e?.target?.value
        : e?.target?.value,
    });
  };

  const uploadPdf = async (e) => {
    e.preventDefault();

    try {
      loader("show");
      let formData = new FormData();
      formData.append("pdfId", articleId);
      formData.append("type", pdfData.file_type);
      formData.append("userId", localStorage.getItem("user_id"));
      formData.append("file", userInputs?.uploadFile?.[0]);

      if (pdfData?.file_type && pdfData.file_type == "ebook") {
        if (typeof userInputs?.title != "undefined") {
          setTemplateName(userInputs?.title);
          formData.append("title", userInputs?.title);
        }

        formData.append("fileId", pdfFileId);
      }
      const res = await postFormData(ENDPOINT.UPDATE_PDF_FILE, formData, {
        header: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (pdfData?.file_type && pdfData.file_type == "ebook") {
        setUserInputs({ ...userInputs, title: "", uploadFile: "" });
      }

      if (pdfData?.file_type && pdfData.file_type == "ebook") {
        let pdfIndex = pdfData.ebookData.findIndex((el) => el.id === pdfFileId);
        pdfData.ebookData[pdfIndex].title = res?.data?.data?.title;
        pdfData.ebookData[pdfIndex].file_name = res?.data?.data?.pdf;
        setTemplatePdf(res?.data?.data?.pdf);
      } else {
        pdfData.file_name = res?.data?.data?.pdf;
        pdfData.title = res?.data?.data?.title;
        setTemplatePdf(res?.data?.data?.pdf);
      }
    } catch (err) {
      console.log("--err", err);
    } finally {
      loader("hide");
    }
    handleClose();
    setUpdateFlag(0);
    setPublishStatus(false);
  };

  const imageOnError = (event) => {
    event.currentTarget.src = BrokenImage;
    event.currentTarget.className = "error";
  };

  const updatePublish = () => {
    setPublishStatus(true);
  };

  const handleNext = async (obj) => {
    loader("show");
    obj.append("pdfId", articleId);
    obj.append("user_id", localStorage.getItem("user_id"));
    obj.append("type", pdfData?.file_type);
    if (pdfData.file_type == "ebook") {
      obj.append("pdfFileId", pdfFileId);
    }

    if(pdfData.file_type == "ebook" && templateAllVersion == 1){
      try{
        let pdfIndex = pdfData.ebookData.findIndex((el) => el.id === pdfFileId);
        pdfData.ebookData[pdfIndex].processed = 1;

        let nextItem = pdfData.ebookData[pdfIndex + 1];
          if (typeof nextItem !== "undefined") {
            setPublishStatus(false);
            pdfData.ebookData[pdfIndex + 1].processed = 1;
            let get_next_id = nextItem.id;
            var link = document.getElementById("template_dyn" + get_next_id);
            link.click();
          } else {
            setPublishStatus(true);
            navigate("/content-detail", {
              // state: { pdfId: articleId,isEdit: isEdit },
              state: { pdfId: articleId,isEdit: isEdit , 
                // title : location?.state?.title,
                title: isLikeRdAccount ? location?.state?.title : '',
                flag:isLikeRdAccount
                ? (location?.state?.flag === "Non-mandatory" ? 'Non-mandatory' : "mandatory")
                : '',
                draft:state?.draft
              },
                
            });
          }
          setPdfData(pdfData);
      }catch(err){
        console.log(err);
      }finally{
        loader("hide");
      }
    }else{
      try {
        const res = await postFormData(ENDPOINT.ADD_PDF_WORD, obj, {
          header: {
            "Content-Type": "multipart/form-data",
          },
        });
        if (pdfData?.file_type && pdfData.file_type == "ebook") {
          let pdfIndex = pdfData.ebookData.findIndex((el) => el.id === pdfFileId);
          pdfData.ebookData[pdfIndex].processed = 1;
          pdfData.ebookData[pdfIndex].image = res?.data?.data?.image;
  
          let nextItem = pdfData.ebookData[pdfIndex + 1];
          if (typeof nextItem !== "undefined") {
            setPublishStatus(false);
            pdfData.ebookData[pdfIndex + 1].processed = 1;
            let get_next_id = nextItem.id;
            var link = document.getElementById("template_dyn" + get_next_id);
            link.click();
          } else {
            setPublishStatus(true);
            navigate("/content-detail", {
              // state: { pdfId: articleId,isEdit: isEdit },
              state: { pdfId: articleId,isEdit: isEdit , 
                // title : location?.state?.title,
                title: isLikeRdAccount ? location?.state?.title : '',
                flag:isLikeRdAccount
                ? (location?.state?.flag === "Non-mandatory" ? 'Non-mandatory' : "mandatory")
                : '',
                draft:state?.draft
              },
                
            });
          }
          setPdfData(pdfData);
        } else {
          setPublishStatus(true);
          navigate("/content-detail", {
            state: { pdfId: articleId,isEdit: isEdit , 
              // title : location?.state?.title,
              title: isLikeRdAccount ? location?.state?.title : '',
              flag: isLikeRdAccount
              ? (location?.state?.flag === "Non-mandatory" ? 'Non-mandatory' : "mandatory")
              : '',
              draft:state?.draft
            },
              
          });
        }
        setApiCallBackFlag(apiCallBackFlag + 1);
      } catch (err) {
        console.log(err);
      } finally {
        loader("hide");
      }
    }
  };

  return (
    <Col className="right-sidebar custom-change">
      <div className="custom-container">
        <Row>
          <div className="page-top-nav sticky">
            <div className="row justify-content-end align-items-center">
              <div className="col-12 col-md-1">
                <div className="header-btn-left">
                  {isLikeRdAccount ? (
                    <>
                    {
                      allowStateVideo ?
                        <Link
                          className="btn btn-bordered btn btn-primary"
                          to={"/library-add-link"}
                          state={{
                            pdfId: state?.pdfId,
                            isEdit: isEdit,
                            allowVideo: allowStateVideo,
                            flag :isLikeRdAccount?(location?.state?.flag === "mandatory"
                              ? "mandatory"
                              : location?.state?.flag === "Non-mandatory"
                              ? "Non-mandatory" : '') :'',
                              title: isLikeRdAccount
                              ? (location?.state?.title)
                              : '',
                              draft:state?.draft
                          }}
                        >
                          Back
                        </Link>
                      :
                      <Link
                        className="btn btn-bordered btn btn-primary"
                        to={location?.state?.flag === "mandatory"
                            ? "/library-mandatory-content"
                            : location?.state?.flag === "Non-mandatory"
                            ? "/library-content"
                            : "/library-create"
                        }
                        state={{
                          flag : isLikeRdAccount?(location?.state?.flag === "mandatory"
                            ? "mandatory"
                            : location?.state?.flag === "Non-mandatory"
                            ? "Non-mandatory" : '') :'',
                            title:isLikeRdAccount
                            ? (location?.state?.title)
                            : '',
                            draft:state?.draft
                        }}
                      >
                        Back
                      </Link>
                    }
                    </>
                  ) : (
                    <Link
                      className="btn btn-bordered btn btn-primary"
                      to="/set-popup"
                      state={{
                        pdfId: state?.pdfId,
                        isEdit: isEdit,
                        allowVideo: allowStateVideo,
                        ibu: pdfData?.ibu?pdfData?.ibu:"",
                        lng:pdfData?.popup_email_content_language?newLanguage[pdfData?.popup_email_content_language]:10,
                        draft:state?.draft
                      }}
                    >
                      Back
                      {/*
                        <svg
                        width="14"
                        height="24"
                        viewBox="0 0 14 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        >
                        <path
                        d="M0.159662 12.0019C0.159662 11.5718 0.323895 11.1417 0.65167 10.8138L10.9712 0.494292C11.6277 -0.16216 12.692 -0.16216 13.3482 0.494292C14.0044 1.15048 14.0044 2.21459 13.3482 2.8711L4.21687 12.0019L13.3479 21.1327C14.0041 21.7892 14.0041 22.8532 13.3479 23.5093C12.6917 24.1661 11.6274 24.1661 10.9709 23.5093L0.65135 13.19C0.323523 12.8619 0.159662 12.4319 0.159662 12.0019Z"
                        fill="#97B6CF"
                        />
                        </svg>
                        */}
                    </Link>
                  )}
                </div>
              </div>
              <div className="col-12 col-md-9">
                <ul className="tabnav-link">
                  {isEdit == 1 ? (
                    <>
                      <li className="">
                        <a href="">Edit Your Content</a>
                      </li>
                      {allowStateVideo ? (
                        <li className="">
                          <a href="">[Embedding Video]</a>
                        </li>
                      ) : null}
                      {!isLikeRdAccount ? (
                        <li className="">
                          <a href="">Edit Consent Option</a>
                        </li>
                      ) : null}
                      <li className="active active-main">
                        <a href="">Approve Your Content &amp; Save</a>
                      </li>
                    </>
                  ) : (
                    <>
                      <li className="">
                        <a href="">Create Your Content</a>
                      </li>
                      {allowStateVideo ? (
                        <li className="">
                          <a href="">[Embedding Video]</a>
                        </li>
                      ) : null}
                      {!isLikeRdAccount ? (
                        <li className="">
                          <a href="">Edit Consent Option</a>
                        </li>
                      ) : null}

                      <li className="active active-main">
                        <a href="">Preview Your Content &amp; Publish</a>
                      </li>
                    </>
                  )}
                </ul>
              </div>
              <div className="col-12 col-md-2">
                <div className="header-btn">
                  {/*<Link
                      className="btn btn-primary btn-bordered move-draft"
                      to="/library-create"
                    >
                      Cancel
                    </Link>*/}

                  <Button
                    onClick={() => {
                      setTrigger((trigger) => trigger + 1);
                    }}
                    className={
                      publishStatus
                        ? "btn btn-primary btn-filled next send_btn"
                        : "btn btn-primary btn-filled next btn-disabled"
                    }
                  >
                    {isEdit == 1 ? "Save" : "Publish"}
                  </Button>
                </div>
              </div>
            </div>
          </div>

          {apiCallBackFlag
            ? pdfData?.file_type &&
              pdfData.file_type == "ebook" && (
                <section className="select-mail-template library-cosent prev_content">
                  <div className="custom-container">
                    <div className="page-title">
                      <h4>Select chapter to preview it</h4>
                    </div>
                    <AliceCarousel
                      mouseTracking
                      disableDotsControls
                      activeIndex={activeIndex}
                      responsive={responsive}
                      onSlideChanged={syncActiveIndex}
                    >
                      {pdfData?.ebookData.map((template, index) => {
                        return (
                          <>
                            <div
                              className={
                                index === 0 || template.processed == 1
                                  ? "item"
                                  : template.id == pdfFileId
                                  ? "item"
                                  : " item disable-link"
                              }
                              onClick={(e) => templateClicked(template, e)}
                              id={"click" + template.id}
                            >
                              <img
                                id={"template_dyn" + template.id}
                                src={template.image}
                                onError={imageOnError}
                                alt=""
                                className={
                                  typeof templateId !== "undefined" &&
                                  templateId == template.id
                                    ? "select_mm"
                                    : ""
                                }
                              />
                              <p>{template.title}</p>
                            </div>
                          </>
                        );
                      })}
                    </AliceCarousel>
                  </div>
                </section>
              )
            : null}
          <div className="create-change-content spc-content">
            <div className="form_action">
              <div className="row">
                <Col className="sublink_right preview-content d-flex flex-column">
                  <>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="edit_pdf_title">
                        <h4 className="edit_content_title">
                          {editTitle ? (
                            <input
                              type="text"
                              className="form-control"
                              id="new-tag"
                              value={titleChange}
                              onChange={(e) => setTitleChange(e.target.value)}
                            />
                          ) : pdfData?.file_type &&
                            pdfData.file_type == "ebook" ? (
                            templateName != "" ? (
                              templateName
                            ) : (
                              pdfData?.ebookData[0].title
                            )
                          ) : titleChange != "" ? (
                            titleChange
                          ) : (
                            pdfData?.title
                          )}

                          {editTitle ? (
                            <>
                              <button
                                className="btn btn-filled"
                                onClick={(e) => {
                                  setEditTitle(false);
                                  updateArticleTitle(titleChange);
                                }}
                              >
                                Save
                              </button>
                              <button
                                className="btn btn-bordered"
                                onClick={(e) => {
                                  setEditTitle(false);
                                  setTitleChange(pdfData?.title);
                                }}
                              >
                                Cancel
                              </button>
                            </>
                          ) : (
                            <button
                              className="btn btn-edit"
                              onClick={(e) => {
                                setEditTitle(true);
                                setTitleChange(
                                  pdfData?.file_type &&
                                    pdfData.file_type == "ebook"
                                    ? templateName != ""
                                      ? templateName
                                      : pdfData?.title
                                    : pdfData?.title
                                );
                              }}
                            >
                              <img
                                src={path_image + "edit-button.svg"}
                                alt="Edit"
                              />
                            </button>
                          )}
                        </h4>
                      </div>
                      <div className="blink_text">
                        <h6>
                          Please verify every page is correct and press
                          'Publish' at the top right when you're sure.
                        </h6>
                      </div>
                      
                      {
                         pdfData.file_type == "ebook" && templateAllVersion == 1 ? null 
                         :
                          <Button className="btn btn-bordered" onClick={handleShow}>
                            Change content file
                          </Button>
                      }
                    </div>
                    <div className="new_preview_pdflink">
                    {newTemplateClicked ? (
                      pdfData?.file_type && pdfData.file_type == "ebook" ? (
                          <>
                            <RenderPdf
                              next={nextFlag}
                              url={templateAllVersion == 1 ? path_image + "videotypeebook.pdf" :templatePdf}
                              handleNext={handleNext}
                              hidePopup="0"
                              trigger={trigger}
                              updatePublish={updatePublish}
                              editStatus={isEdit}
                              customKey={isKey}
                            />
                          </>
                      ) : (
                        <RenderPdf
                          next={nextFlag}
                          url={pdfData?.file_name}
                          handleNext={handleNext}
                          hidePopup="0"
                          trigger={trigger}
                          updatePublish={updatePublish}
                          editStatus={isEdit}
                        />
                      )
                    ) : null}
                    </div>
                  </>
                </Col>
              </div>
            </div>
          </div>
          <Modal
            show={show}
            onHide={handleClose}
            className="send-confirm preview-content"
            id="download-qr"
          >
            <Modal.Header>
              <h5 className="modal-title" id="staticBackdropLabel">
                Change file
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                onClick={handleClose}
              ></button>
            </Modal.Header>
            <Modal.Body>
              <Form>
                {pdfData?.file_type && pdfData.file_type == "ebook" ? (
                  <div className="form-group">
                    <label htmlFor="">Chapter title </label>
                    <input
                      type="text"
                      placeholder="Type chapter title"
                      name="chapter_title"
                      className="form-control"
                      value={titleNew}
                      onChange={(e) => {
                        setTitleNew(e.target.value);
                        handleChange(e, "title");
                      }}
                    />
                  </div>
                ) : null}
                <div className="form-group">
                  <div className="upload-file-box">
                    <div className="box">
                      <input
                        type="file"
                        name="file-5[]"
                        id="file-5"
                        className="inputfile inputfile-5"
                        accept="application/pdf"
                        onChange={(e) => handleChange(e, "uploadFile")}
                      />
                      <label htmlFor="file-5">
                        <span>Choose Your File</span>
                      </label>
                      {userInputs?.uploadFile?.[0]?.name ? (
                        <p className="uploaded-file">
                          {userInputs?.uploadFile?.[0].name}
                        </p>
                      ) : (
                        <p>Upload your PDF</p>
                      )}
                    </div>
                  </div>
                </div>
              </Form>
            </Modal.Body>
            <div className="modal-footer">
              <button
                type="button"
                className={
                  updateFlag == 0
                    ? "btn btn-primary save btn-filled move-draft btn-disabled"
                    : "btn btn-primary save btn-filled move-draft"
                }
                onClick={uploadPdf}
              >
                Upload
              </button>
            </div>
          </Modal>
        </Row>
      </div>
    </Col>
  );
};

export default PreviewContent;
